.dropdown-menu {
  padding: 0rem !important;
}

.first-dropdown .dropdown-toggle::after {
  display: none;
}

.first-dropdown .dropdown-menu {
  width: 400px;
  max-height: 500px; /* Change this value to your desired height */
  overflow-y: auto;
}

.dropdown-toggle::after {
  font-size: 18px;
}

.mark-all-as-read-btn {
  padding: 3px 6px !important;
  background-color: #fff !important;
  color: #000 !important;
  border: none !important;
  cursor: pointer !important;
}
