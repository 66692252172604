.main_body {
  height: 100vh;  
  background-color: black;
  color: white;
}

input {
  font-size: 16px !important;
}
.outer_div{
  width: 30%;
}
.submit-btn {
  width: 100%;
  margin-top: 2rem;
  background-color: rgb(42, 112, 125);
  font-size: 18px;
  color: black;
}


@media screen and (max-width: 768px) {
  .outer_div{
    width: 80%;
  }
}