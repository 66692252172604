/* .divcss{
  border-bottom: solid 3px rgba(75,192,192,1);
  padding: 15px;
} */

.divcss {
  border-bottom: solid 3px rgba(169, 169, 169);
  padding: 20px;
  width: 118% !important;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.top1 {
  width: 100%;
  height: 100%;
  display: flex;
}

/* .p1{ */
/* width:50%;
  height: 40%; */
/* ========= */
/* margin-top: 5px;
  font-size: 14px;
  }
  .p2{

    margin-top: 5px;
  font-size: 14px;
  /* } */
.p1 {
  font-size: 14px;
  width: 70%;
}
.p2 {
  font-size: 14px;
  width: 30%;
}
