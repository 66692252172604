@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

.main-nav-active {
  background-color: #666666;
}

.sidenav {
  /* width: 100%; */
  /* width: 121%; */
  z-index: 1;
  background-color: #303230;
  height: 90.25vh;
  /* overflow: hidden; */
  /* padding: 10px 0px 0px 9px; */
}

.sidenav a:hover {
  text-decoration: none;
}

/* .sidenav .main-nav:active{
 background: #d3d3d3;
 border-radius: 35px;
 padding: 0px 20px;
 line-height: 50px;
} */
.sidenav .main-nav:first-child {
  padding-top: 27px;
}

.sidenav .main-nav {
  position: relative;
  padding: 13px 20px;
  text-decoration: none;
  font-size: 18px;
  color: #eee;
  display: block;
  /* font-weight: bold; */
}

.sidenav .align-bottom {
  position: absolute;
  vertical-align: bottom;
  bottom: 0;
}

.sidenav .main-nav:hover {
  cursor: pointer;
  color: #eee;
  background-color: #666666;
  /* font-weight: bold; */
}

.sidenav .main-nav .fas {
  padding: 5px;
  /* font-size: 5px;
    width: 10px;
    color: #eee;
    font-weight: lighter; */
}

/* .main { */

/* margin-left: 140px; Same width as the sidebar + left position in px */

/* font-size: 28px; Increased text to enable scrolling */

/* padding: 0px 10px; */

/* } */

h2 {
  color: white;
  font-weight: bold;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
    width: 100%;
    height: 70%;
  }
  .sidenav a {
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  .sidenav Link:not(:first-child) {
    display: none;
  }
  .sidenav Link.navbar-brand {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .sidenav.responsive {
    position: relative;
  }
  .sidenav.responsive Link {
    position: absolute;
    right: 0;
    top: 0;
  }
  /* .sidenav {
      position: relative;
      top: 0;
      right: 0;
      display: block;
      text-align: left;
    } */
  /* .sidenav .navbar-brand .logo-img{
      position: absolute;
      top: 0;
      right: 0;
    } */
  .sidenav.responsive :link {
    float: none;
    display: block;
    text-align: left;
  }
}
