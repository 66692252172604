.login_top {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 1500px 650px;
}
.links {
  text-align: center;
}
.h-100 {
  min-height: calc(100vh - 10px) !important;
}
.col-lg-6.log {
  background-color: rgba(0, 0, 0, 0.5);
}

.my-login-page .brand img {
  width: 100%;
}

.my-login-page .card-wrapper {
  width: 700px;
}

.my-login-page .form-control {
  border-width: 2.3px;
}

.my-login-page .form-group label {
  width: 100%;
}

.my-login-page .btn.btn-block {
  padding: 12px 10px;
}

.my-login-page .margin-top20 {
  margin-top: 20px;
}

.my-login-page .no-margin {
  margin: 0;
}

.custom-card-margin {
  margin-top: 30px;
}

a:-webkit-any-link {
  cursor: pointer;
}
/* .fixed-layout .page-wrapper {
	padding-top: 65px;
} */

.Forgot_label{
  width: 250px;
  margin-left: 280px;
}
@media screen and (max-width:768px) {
  .signup_div {
    margin-top: -100px;
    margin-left: -40px;
  }
  .Forgot_label{
    margin-left: 95px;
    width: 300px;
  }
}