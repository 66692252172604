TableCell:focus {
  outline: none !important;
}
TableRow::focus {
  outline: none !important;
}
TableRow:hover {
  background-color: transparent !important;
}
TableCell:hover {
  background-color: transparent !important;
}
