.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  overflow-x: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.contentScreen {
  margin-left: 30px;
  margin-right: 20px;
  margin-top: 40px;
  height: 60.95vh;
  overflow: "scroll";
}
@media screen {
  .contentScreen {
    height: 70%;
  }
}
.sideBar {
  height: 90.95vh;
  position: sticky;
}
/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main {
  margin-left: 160px; /* Same as the width of the sidenav */
  /* Increased text to enable scrolling */
  padding: 0px 10px;
}
.react-tel-input .selected-flag {
  width: 43px !important;
}
.react-datepicker__input-container {
  width: 91% !important;
  margin-left: -5px;
}
.MuiTableCell-stickyHeader {
  position: static !important;
}
/* .css-lyms1i-MuiGrid-root {
  display: flex !important;
  justify-content: center !important;
  overflow-y: hidden !important;
} */
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  height: 38px !important;
}
.impinput {
  border-top-right-radius: 0px !important ;
  border-bottom-right-radius: 0px !important;
}
.pdf {
  width: fit-content;
}
