.main__body {
  /* margin-left: 2rem; */
  border-radius: 2rem 2rem 2rem 2rem;
  background-color: #e4e4e4;
  box-shadow: 5px 5px 8px #888888;
  /* padding: 1.5rem; */
  width: 100%;
  height: 60%;
}
/* 
  .main__form {
    padding: 1rem;
    
  } */

.form-control {
  border-radius: 0.5rem;
}
.div_small {
  margin-left: 40px;
}
